
function getItem(key){
    let v = localStorage.getItem(key);
    try{
        let x = JSON.parse(v);
        return x;
    }catch(e){
        console.log("storage get item err", e);
    }
    return v;
}

function setItem(key, v){
    try{
        let s = JSON.stringify(v);
        localStorage.setItem(key, s);
    }catch (e){ console.log("err", e) }finally {}
}

function removeItem(key){
    localStorage.removeItem(key)
}
function clear(){
    const bl = window.confirm("are you sure you want to clear all the storage items?")
    if(bl == true){
        localStorage.clear();
        console.log("local storage cleared")
    }
}
export const storage = {
    getItem,
    setItem,
    removeItem,
    clear,
}